<template>
  <settings :is-edit="true" />
</template>

<script>
import Settings from './components/Settings';

export default {
  name: 'SettingsForm',
  components: { Settings }
};
</script>
